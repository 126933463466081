export var FilterEnums;
(function (FilterEnums) {
    FilterEnums["last30Minutes"] = "last30Minutes";
    FilterEnums["last1Hour"] = "last1Hour";
    FilterEnums["last4Hours"] = "last4Hours";
    FilterEnums["last12Hours"] = "last12Hours";
    FilterEnums["last24Hours"] = "last24Hours";
    FilterEnums["last48Hours"] = "last48Hours";
    FilterEnums["last3Days"] = "last3Days";
    FilterEnums["last7Days"] = "last7Days";
    FilterEnums["last1Month"] = "last1Month";
    FilterEnums["last3Months"] = "last3Months";
    FilterEnums["all"] = "all";
})(FilterEnums || (FilterEnums = {}));
