import { FilterEnums } from '../types/enums';
export function openSidebar() {
    if (typeof window !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
}
export function closeSidebar() {
    if (typeof window !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
}
export function toggleSidebar() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        var slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--SideNavigation-slideIn');
        if (slideIn) {
            closeSidebar();
        }
        else {
            openSidebar();
        }
    }
}
export var hasChild = function (parent, child) {
    var node = child.parentNode;
    while (node != null) {
        if (node == parent) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
};
export var createQueryArrayParam = function (key, values) {
    var encodedValues = values.map(function (value) { return encodeURIComponent(value); });
    var queryParams = encodedValues.map(function (value) { return "".concat(key, "=").concat(value); });
    var queryString = queryParams.join('&');
    return queryString;
};
export var generateQueryParams = function (params) {
    if (!params || Object.keys(params).length === 0) {
        return '';
    }
    var queryParams = [];
    for (var key in params) {
        if (Array.isArray(params[key])) {
            queryParams.push(createQueryArrayParam(key, params[key]));
        }
        else if (Object.prototype.hasOwnProperty.call(params, key)) {
            if (params[key] != null && params[key] !== undefined) {
                var encodedKey = encodeURIComponent(key);
                var encodedValue = encodeURIComponent(params[key]);
                queryParams.push("".concat(encodedKey, "=").concat(encodedValue));
            }
        }
    }
    return queryParams.join('&');
};
export function toggleMessagesPane() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        var slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--MessagesPane-slideIn');
        if (slideIn) {
            closeMessagesPane();
        }
        else {
            openMessagesPane();
        }
    }
}
export function openMessagesPane() {
    if (typeof window !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--MessagesPane-slideIn', '1');
    }
}
export function closeMessagesPane() {
    if (typeof window !== 'undefined') {
        document.documentElement.style.removeProperty('--MessagesPane-slideIn');
        document.body.style.removeProperty('overflow');
    }
}
function getStartOfDay(date) {
    var start = new Date(date);
    start.setHours(0, 0, 0, 0);
    return start;
}
function getEndOfDay(date) {
    var end = new Date(date);
    end.setHours(23, 59, 59, 999);
    return end;
}
function subtractDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
}
function subtractMonths(date, months) {
    var result = new Date(date);
    result.setMonth(result.getMonth() - months);
    // Handle end-of-month cases
    if (date.getDate() !== result.getDate()) {
        result.setDate(0);
    }
    return result;
}
export var dateFilterValuesGenerator = function (target) {
    var now = new Date();
    switch (target) {
        case FilterEnums.last30Minutes:
            return {
                startDate: new Date(now.getTime() - 30 * 60 * 1000).toISOString(),
                endDate: now.toISOString(),
            };
        case FilterEnums.last1Hour:
            return {
                startDate: new Date(now.getTime() - 1 * 60 * 60 * 1000).toISOString(),
                endDate: now.toISOString(),
            };
        case FilterEnums.last4Hours:
            return {
                startDate: new Date(now.getTime() - 4 * 60 * 60 * 1000).toISOString(),
                endDate: now.toISOString(),
            };
        case FilterEnums.last12Hours:
            return {
                startDate: new Date(now.getTime() - 12 * 60 * 60 * 1000).toISOString(),
                endDate: now.toISOString(),
            };
        case FilterEnums.last24Hours:
            return {
                startDate: new Date(now.getTime() - 24 * 60 * 60 * 1000).toISOString(),
                endDate: now.toISOString(),
            };
        case FilterEnums.last48Hours:
            return {
                startDate: new Date(now.getTime() - 48 * 60 * 60 * 1000).toISOString(),
                endDate: now.toISOString(),
            };
        case FilterEnums.last3Days:
            return {
                startDate: getStartOfDay(subtractDays(now, 3)).toISOString(),
                endDate: now.toISOString(),
            };
        case FilterEnums.last7Days:
            return {
                startDate: getStartOfDay(subtractDays(now, 7)).toISOString(),
                endDate: now.toISOString(),
            };
        case FilterEnums.last1Month:
            return {
                startDate: getStartOfDay(subtractMonths(now, 1)).toISOString(),
                endDate: now.toISOString(),
            };
        case FilterEnums.last3Months:
            return {
                startDate: getStartOfDay(subtractMonths(now, 3)).toISOString(),
                endDate: now.toISOString(),
            };
        default:
            return {};
    }
};
