var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { GlobalStyles, Box, List, ListItem, ListItemButton, listItemButtonClasses, ListItemContent, Typography, Sheet, } from "@mui/joy";
import HeadsetMicRoundedIcon from "@mui/icons-material/HeadsetMicRounded";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import { Link, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CallRoundedIcon from "@mui/icons-material/CallRounded";
import { closeSidebar } from "../../../utils";
function Toggler(_a) {
    var _b = _a.defaultExpanded, defaultExpanded = _b === void 0 ? false : _b, renderToggle = _a.renderToggle, children = _a.children;
    var _c = React.useState(defaultExpanded), open = _c[0], setOpen = _c[1];
    return (_jsxs(React.Fragment, { children: [renderToggle({ open: open, setOpen: setOpen }), _jsx(Box, __assign({ sx: {
                    display: "grid",
                    gridTemplateRows: open ? "1fr" : "0fr",
                    transition: "0.2s ease",
                    "& > *": {
                        overflow: "hidden",
                    },
                } }, { children: children }))] }));
}
export default function Sidebar() {
    var _a;
    var location = useLocation();
    return (_jsxs(Sheet, __assign({ className: "Sidebar", sx: {
            position: { xs: "fixed", md: "sticky" },
            transform: {
                xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
                md: "none",
            },
            transition: "transform 0.4s, width 0.4s",
            zIndex: 10000,
            height: "calc(100dvh - var(--TopNavBar-height))",
            width: "var(--Sidebar-width)",
            top: "var(--TopNavBar-height)",
            p: 2,
            paddingTop: 0,
            flexShrink: 0,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            borderRight: "1px solid",
            borderColor: "divider",
        } }, { children: [_jsx(GlobalStyles, { styles: function (theme) {
                    var _a;
                    return ({
                        ":root": (_a = {
                                "--Sidebar-width": "220px"
                            },
                            _a[theme.breakpoints.up("lg")] = {
                                "--Sidebar-width": "240px",
                            },
                            _a),
                    });
                } }), _jsx(Box, { className: "Sidebar-overlay", sx: {
                    position: "fixed",
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    opacity: "var(--SideNavigation-slideIn)",
                    backgroundColor: "var(--joy-palette-background-backdrop)",
                    transition: "opacity 0.4s",
                    transform: {
                        xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
                        lg: "translateX(-100%)",
                    },
                }, onClick: function () { return closeSidebar(); } }), _jsx(Box, __assign({ sx: (_a = {
                        minHeight: 0,
                        overflow: "hidden auto",
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column"
                    },
                    _a["& .".concat(listItemButtonClasses.root)] = {
                        gap: 1.5,
                    },
                    _a) }, { children: _jsxs(List, __assign({ size: "sm", sx: {
                        gap: 1,
                        "--List-nestedInsetStart": "30px",
                        "--ListItem-radius": function (theme) { return theme.vars.radius.sm; },
                    } }, { children: [_jsx(ListItem, { children: _jsxs(ListItemButton, __assign({ component: Link, to: "/ai-agents", selected: location.pathname == "/ai-agents" }, { children: [_jsx(HeadsetMicRoundedIcon, {}), _jsx(ListItemContent, { children: _jsx(Typography, __assign({ level: "title-sm" }, { children: "AI Agents" })) })] })) }), _jsx(ListItem, { children: _jsxs(ListItemButton, __assign({ component: Link, to: "/call-conversations", selected: location.pathname == "/call-conversations" }, { children: [_jsx(CallRoundedIcon, {}), _jsx(ListItemContent, { children: _jsx(Typography, __assign({ level: "title-sm" }, { children: "Call History" })) })] })) }), _jsx(ListItem, { children: _jsxs(ListItemButton, __assign({ component: Link, to: "/call-analytics", selected: location.pathname === "/call-analytics" }, { children: [_jsx(TrendingUpRoundedIcon, {}), _jsx(ListItemContent, { children: _jsx(Typography, __assign({ level: "title-sm" }, { children: "Call Analytics" })) })] })) }), _jsx(ListItem, __assign({ nested: true }, { children: _jsx(Toggler, __assign({ renderToggle: function (_a) {
                                    var open = _a.open, setOpen = _a.setOpen;
                                    // Determine if any of the child items are selected
                                    var isAnyChildSelected = location.pathname === "/video-tutorials/1" ||
                                        location.pathname === "/video-tutorials/2" ||
                                        location.pathname === "/video-tutorials/3";
                                    // Automatically expand if a child is selected
                                    var initialOpen = open || isAnyChildSelected;
                                    return (_jsxs(ListItemButton, __assign({ onClick: function () { return setOpen(!initialOpen); } }, { children: [_jsx(MenuBookRoundedIcon, {}), _jsx(ListItemContent, { children: _jsx(Typography, __assign({ level: "title-sm" }, { children: "Video Tutorials" })) }), _jsx(KeyboardArrowDownIcon, { sx: {
                                                    transform: initialOpen ? "rotate(180deg)" : "none",
                                                } })] })));
                                } }, { children: _jsxs(List, __assign({ sx: { gap: 0.5 } }, { children: [_jsx(ListItem, __assign({ sx: { mt: 0.5 } }, { children: _jsx(ListItemButton, __assign({ component: Link, to: "/video-tutorials/1", selected: location.pathname == "/video-tutorials/1" }, { children: _jsx(ListItemContent, { children: _jsx(Typography, __assign({ level: "title-sm" }, { children: "How to Setup FAQ" })) }) })) })), _jsx(ListItem, __assign({ sx: { mt: 0.5 } }, { children: _jsx(ListItemButton, __assign({ component: Link, to: "/video-tutorials/2", selected: location.pathname == "/video-tutorials/2" }, { children: _jsx(ListItemContent, { children: _jsx(Typography, __assign({ level: "title-sm" }, { children: "How to Change Question" })) }) })) })), _jsx(ListItem, __assign({ sx: { mt: 0.5 } }, { children: _jsx(ListItemButton, __assign({ component: Link, to: "/video-tutorials/3", selected: location.pathname == "/video-tutorials/3" }, { children: _jsx(ListItemContent, { children: _jsx(Typography, __assign({ level: "title-sm" }, { children: "How to Test Agent" })) }) })) }))] })) })) }))] })) }))] })));
}
