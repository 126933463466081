var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Skeleton, Box, Typography, List, ListDivider, ListItem, Stack, Chip, Tooltip, FormControl, Input, useColorScheme, Button, } from "@mui/joy";
import ListItemButton from "@mui/joy/ListItemButton";
import AddIcon from "@mui/icons-material/Add";
import useExternalJwtAuth from "../../../../data/hooks/useExternalJwtAuth";
import { useEffect } from "react";
export default function AIAgentsList(props) {
    var flows = props.flows, selectedFlowId = props.selectedFlowId, loading = props.loading, onFlowSelect = props.onFlowSelect;
    var mode = useColorScheme().mode;
    var _a = useExternalJwtAuth(), user = _a.userInfo, fetchUserInfo = _a.fetchUserInfo;
    var onNewFlow = function () {
        // Redirect to an external page
        console.log(user);
        window.location.href = user.iss; // Replace with the desired external URL
    };
    var _b = React.useState(new Set()), overflowingTextIds = _b[0], setOverflowingTextIds = _b[1];
    var textRefs = React.useRef({});
    var _c = React.useState(""), searchQuery = _c[0], setSearchQuery = _c[1];
    var handleItemClick = function (flowId) {
        onFlowSelect(flowId);
    };
    useEffect(function () {
        fetchUserInfo();
    }, []);
    var handleSearchChange = function (event) {
        setSearchQuery(event.target.value.toLowerCase());
    };
    // Filtered flows based on search query
    var filteredFlows = flows.filter(function (flow) {
        return (flow.name && flow.name.toLowerCase().includes(searchQuery)) ||
            (flow.flowDescription &&
                flow.flowDescription.toLowerCase().includes(searchQuery));
    });
    // Function to check overflowing text
    React.useLayoutEffect(function () {
        if (!loading && flows.length > 0) {
            var newOverflowingIds_1 = new Set();
            Object.entries(textRefs.current).forEach(function (_a) {
                var flowId = _a[0], element = _a[1];
                if (element && element.scrollWidth > element.clientWidth) {
                    newOverflowingIds_1.add(flowId);
                }
            });
            setOverflowingTextIds(newOverflowingIds_1);
        }
    }, [flows, loading]); // Run this effect after loading is false and flows are available
    return (_jsxs(Box, __assign({ sx: { pr: 1, pl: 1, minWidth: "267px" } }, { children: [_jsx(FormControl, __assign({ sx: { display: { sm: "flex-column", md: "flex-row" }, gap: 0 } }, { children: _jsx(Input, { placeholder: "  Search", size: "sm", color: "neutral", value: searchQuery, onChange: handleSearchChange, sx: function (theme) {
                        var _a;
                        return ({
                            mt: 1,
                            width: "100%",
                            backgroundColor: (_a = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _a === void 0 ? void 0 : _a.primary.listItemButton,
                        });
                    } }) })), _jsx(List, { children: loading ? (_jsxs(_Fragment, { children: [_jsx(ListItem, { children: _jsx(Skeleton, { height: 48, width: 234, sx: { position: "relative" } }) }), _jsx(ListItem, { children: _jsx(Skeleton, { height: 48, width: 234, sx: { position: "relative" } }) }), _jsx(ListItem, { children: _jsx(Skeleton, { height: 48, width: 234, sx: { position: "relative" } }) })] })) : (filteredFlows.map(function (flow, index) { return (_jsxs(React.Fragment, { children: [index === 0 ? (_jsxs(_Fragment, { children: [_jsx(ListItem, { children: _jsxs(Box, __assign({ sx: {
                                            display: "flex",
                                            alignItems: "center",
                                            width: "100%",
                                            p: 1,
                                            pl: 0,
                                        } }, { children: [_jsx(Typography, __assign({ level: "title-lg", sx: {
                                                    color: "#4C4E6461",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    flex: 1,
                                                } }, { children: "AI Agents" })), _jsx(Button, __assign({ startDecorator: _jsx(AddIcon, {}), onClick: function () { return onNewFlow(); }, sx: {
                                                    cursor: "pointer",
                                                }, color: "neutral", variant: "soft" }, { children: "Add New" }))] })) }), _jsx(ListDivider, { sx: { m: 0 } })] })) : null, _jsx(ListItem, { children: _jsx(ListItemButton, __assign({ selected: flow.id === selectedFlowId, sx: {
                                    p: 2,
                                }, onClick: function () { return handleItemClick(flow.id); } }, { children: _jsx(Box, __assign({ sx: { width: "100%", maxWidth: "200px" } }, { children: _jsxs("div", { children: [_jsxs(Stack, __assign({ direction: "row", alignItems: "center", sx: {
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                } }, { children: [_jsx(Tooltip, __assign({ placement: "right", title: flow.name, disableHoverListener: !overflowingTextIds.has(flow.id) }, { children: _jsx(Typography, __assign({ level: "title-sm", sx: {
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                whiteSpace: "nowrap",
                                                                maxWidth: "calc(100% - 60px)", // adjust max width to prevent overlap with Chip
                                                            }, ref: function (el) { return (textRefs.current[flow.id] = el); } }, { children: flow.name })) })), flow.isWorkspaceTemplate && (_jsx(Chip, __assign({ size: "sm", color: "warning", variant: "outlined", sx: {
                                                            ml: 1,
                                                            fontSize: "10px",
                                                            height: "20px",
                                                            padding: "0 4px",
                                                        } }, { children: "Template" })))] })), _jsx(Typography, __assign({ sx: { mt: 1 }, level: "body-sm" }, { children: flow.flowDescription }))] }) })) })) }), _jsx(ListDivider, { sx: { m: 0 } })] }, index)); })) })] })));
}
