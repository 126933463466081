var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { Box, Chip, IconButton, Tooltip } from "@mui/joy";
import DownloadIcon from "@mui/icons-material/Download"; // Import download icon
export default function MessagesPaneHeader(props) {
    var sender = props.sender, recordingPath = props.recordingPath;
    var CallEndReasonNames = {
        0: "None",
        1: "Error",
        2: "User Hangup",
        3: "Inactivity Hangup",
        4: "User Agent Request",
        5: "Time Limit Exceeded",
        6: "Call End Detected",
    };
    var CallEndReasonColors = {
        0: "neutral",
        1: "danger",
        2: "warning",
        3: "neutral",
        4: "warning",
        5: "danger",
        6: "success", // Call End Detected
    };
    console.log(sender);
    return (_jsxs(Stack, __assign({ direction: "row", sx: {
            justifyContent: "space-between",
            py: { xs: 2, md: 2 },
            px: { xs: 1, md: 2 },
            borderBottom: "1px solid",
            borderColor: "divider",
            backgroundColor: "background.body",
        } }, { children: [_jsx(Stack, __assign({ direction: "row", spacing: { xs: 1, md: 2 }, sx: { alignItems: "center" } }, { children: _jsxs(Box, { children: [_jsx(Typography, __assign({ component: "h2", noWrap: true, sx: { fontWeight: "lg", fontSize: "lg" } }, { children: sender.name })), _jsxs(Typography, __assign({ sx: {
                                mt: 0.5,
                                display: "flex",
                                alignItems: "center",
                                gap: 0.5, // Space between duration text and Chip
                            }, level: "body-sm" }, { children: [sender.flowName !== null && sender.flowName !== null && (_jsx(Tooltip, __assign({ title: "Agent Name: " + sender.flowName, placement: "right" }, { children: _jsx(Chip, __assign({ variant: "outlined", color: "primary", size: "sm", sx: {
                                            maxWidth: 100,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis", // Show ellipsis if text overflows
                                        } }, { children: sender.flowName })) }))), sender.endReason !== null && sender.endReason !== undefined && (_jsx(Tooltip, __assign({ title: "Call End Reason: " + CallEndReasonNames[sender.endReason] ||
                                        "Unknown Reason", placement: "right" }, { children: _jsx(Chip, __assign({ variant: "outlined", color: CallEndReasonColors[sender.endReason] || "neutral", size: "sm", sx: {
                                            maxWidth: 150,
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis", // Show ellipsis if text overflows
                                        } }, { children: CallEndReasonNames[sender.endReason] || "Unknown Reason" })) })))] }))] }) })), recordingPath && (_jsxs(Box, __assign({ sx: {
                    display: "flex",
                    alignItems: "center",
                } }, { children: [_jsx("audio", { controls: true, src: recordingPath }), _jsx(IconButton, __assign({ component: "a", href: recordingPath, download: true, sx: { ml: 1 } }, { children: _jsx(DownloadIcon, {}) }))] })))] })));
}
