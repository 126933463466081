var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { CssVarsProvider, extendTheme } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Sidebar from "./components/ui/Sidebar";
import Header from "../src/components/ui/Header";
import TagManager from "react-gtm-module";
import Main from "./Main";
import ProtectedRoute from "./components/ProtectedRoute";
import TopNavBar from "./components/ui/TopNavBar";
var theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    listItemButton: "#EBEBF4",
                },
            },
        },
        dark: {
            palette: {},
        },
    },
    components: {
        JoyListItemButton: {
            styleOverrides: {
                root: function (_a) {
                    var _b, _c, _d, _e;
                    var ownerState = _a.ownerState, theme = _a.theme;
                    return {
                        backgroundColor: ownerState.selected
                            ? (_b = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _b === void 0 ? void 0 : _b.primary.listItemButton
                            : "",
                        "&:hover": {
                            backgroundColor: "".concat((_c = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _c === void 0 ? void 0 : _c.primary.listItemButton, " !important"),
                        },
                        "&.Mui-selected": {
                            backgroundColor: "".concat((_d = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _d === void 0 ? void 0 : _d.primary.listItemButton, " !important"),
                            "&:hover": {
                                backgroundColor: "".concat((_e = theme === null || theme === void 0 ? void 0 : theme.palette) === null || _e === void 0 ? void 0 : _e.primary.listItemButton, " !important"),
                            },
                        },
                    };
                },
            },
        },
    },
});
function App() {
    useEffect(function () {
        var tagManagerArgs = {
            gtmId: "GTM-TMJ7BX4",
        };
        TagManager.initialize(tagManagerArgs);
    }, []);
    return (_jsxs(CssVarsProvider, __assign({ theme: theme, disableTransitionOnChange: true }, { children: [_jsx(CssBaseline, {}), _jsxs(Box, __assign({ sx: { display: "flex", minHeight: "100dvh" } }, { children: [_jsx(Header, {}), _jsx(ProtectedRoute, { children: _jsx(TopNavBar, {}) }), _jsx(ProtectedRoute, { children: _jsx(Sidebar, {}) }), _jsx(Main, {})] }))] })));
}
export default App;
