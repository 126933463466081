var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { GlobalStyles, Box, Typography, Avatar, Sheet, Menu, MenuItem, Divider, useColorScheme, } from "@mui/joy";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import useExternalJwtAuth from "../../../data/hooks/useExternalJwtAuth";
import Icon from "../Icon";
import ColorSchemeToggle from "../ColorSchemeToggle";
import ClickAwayListener from "@mui/material/ClickAwayListener";
export default function TopNavBar() {
    var _a = useExternalJwtAuth(), user = _a.userInfo, fetchUserInfo = _a.fetchUserInfo, logout = _a.logout;
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var mode = useColorScheme().mode;
    useEffect(function () {
        fetchUserInfo();
    }, []);
    var handleMenuToggle = function (event) {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var handleClickAway = function () {
        if (anchorEl) {
            handleClose();
        }
    };
    return (_jsxs(Sheet, __assign({ className: "TopNavBar", sx: {
            position: "fixed",
            top: 0,
            width: "100%",
            height: "64px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "8px 8px",
        } }, { children: [_jsx(GlobalStyles, { styles: function (theme) { return ({
                    ":root": {
                        "--TopNavBar-height": "64px",
                    },
                }); } }), _jsx(Box, __assign({ sx: {
                    display: "flex",
                    alignItems: "center",
                    pl: "16px",
                    pt: "8px",
                    gap: 2,
                } }, { children: _jsx(Icon, { name: mode === "dark" ? "phonexaLogoDark" : "phonexaLogo", width: "143px", height: "26px", color: "#266CC7" }) })), _jsx(ClickAwayListener, __assign({ onClickAway: handleClickAway }, { children: _jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center", gap: 1 } }, { children: [_jsxs(Box, __assign({ sx: {
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                cursor: "pointer",
                            }, onClick: handleMenuToggle }, { children: [_jsx(Avatar, { src: user === null || user === void 0 ? void 0 : user.profileImageUrl, alt: (user === null || user === void 0 ? void 0 : user.email) || "User Avatar", sx: { width: 32, height: 32 } }), _jsx(Typography, __assign({ level: "body-sm", noWrap: true, sx: { marginRight: "8px" } }, { children: (user === null || user === void 0 ? void 0 : user.name) || (user === null || user === void 0 ? void 0 : user.email) }))] })), _jsxs(Menu, __assign({ anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleClose, sx: {
                                mt: 1,
                                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px",
                                padding: "8px",
                            } }, { children: [_jsxs(MenuItem, __assign({ disabled: true }, { children: [_jsx(Avatar, { src: user === null || user === void 0 ? void 0 : user.profileImageUrl, alt: (user === null || user === void 0 ? void 0 : user.email) || "User Avatar", sx: { width: 32, height: 32, marginRight: "8px" } }), _jsx(Box, { children: _jsx(Typography, __assign({ level: "body-xs" }, { children: user === null || user === void 0 ? void 0 : user.email })) })] })), _jsx(Divider, {}), _jsx(MenuItem, { children: _jsxs(Box, __assign({ sx: {
                                            display: "flex",
                                            alignItems: "center",
                                            gap: 1,
                                            width: "100%",
                                        } }, { children: [_jsx(Typography, __assign({ level: "body-sm", sx: { flexGrow: 1 } }, { children: "Dark mode" })), _jsx(ColorSchemeToggle, {})] })) }), _jsx(Divider, {}), _jsxs(MenuItem, __assign({ onClick: function () {
                                        logout();
                                        handleClose();
                                    } }, { children: [_jsx(LogoutRoundedIcon, { sx: { marginRight: "8px" } }), _jsx(Typography, __assign({ level: "body-sm" }, { children: "Sign out" }))] }))] }))] })) }))] })));
}
