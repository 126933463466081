var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { Box, Card, CardContent, Typography, Stack } from "@mui/joy";
import { useLocation } from "react-router-dom";
export var VideoTutorials = function () {
    var location = useLocation();
    var _a = useState([
        {
            id: 1,
            title: "Getting Started with the Platform",
            description: "Learn the basics of navigating and using the platform.",
            loomUrl: "https://www.youtube.com/embed/7QmpRIKomgQ",
        },
        {
            id: 2,
            title: "Advanced Features Walkthrough",
            description: "Explore the advanced features to get the most out of the platform.",
            loomUrl: "https://www.youtube.com/embed/kyet47-OKbY",
        },
        {
            id: 3,
            title: "How to Troubleshoot Common Issues",
            description: "Quick fixes for common problems you might encounter.",
            loomUrl: "https://www.youtube.com/embed/XBsJv3pa8ko",
        },
    ]), videos = _a[0], setVideos = _a[1];
    // Get the selected tutorial based on URL
    var selectedTutorialId = parseInt(location.pathname.split("/").pop() || "0", 10);
    var selectedVideo = videos.find(function (video) { return video.id === selectedTutorialId; });
    return (_jsx(Stack, __assign({ direction: "column", spacing: 2, sx: { width: "100%", height: "100%" } }, { children: selectedVideo ? (_jsxs(_Fragment, { children: [_jsx(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", sx: { px: 2 } }, { children: _jsx(Typography, __assign({ sx: { mt: 2 }, level: "h2", component: "h1" }, { children: selectedVideo.title })) })), _jsx(Box, __assign({ sx: { mt: 1, px: 2, width: "100%" } }, { children: _jsxs(Card, { children: [_jsx(CardContent, { children: _jsx(Box, __assign({ sx: {
                                        mt: 2,
                                        position: "relative",
                                        maxWidth: "960px",
                                        margin: "0",
                                    } }, { children: _jsx("iframe", { src: selectedVideo.loomUrl, allowFullScreen: true, title: selectedVideo.title, style: {
                                            width: "100%",
                                            height: "540px",
                                            borderRadius: "8px",
                                        } }) })) }), _jsx(Typography, __assign({ level: "body-md", sx: { mt: 1 } }, { children: selectedVideo.description }))] }) }))] })) : (_jsx(Typography, __assign({ sx: { px: 2, mt: 2 } }, { children: "No tutorial found for the selected ID." }))) })));
};
